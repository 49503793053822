import React, { useState } from "react"
import MainLogo from "../../img/logo/TeamKangourou_Logo.svg"

export default function Navbar() {
  const [isToogled, setToogled] = useState(false)

  const toogleBugerMenu = () => {
    setToogled(!isToogled)
  }

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src={MainLogo} alt="Logo" width="112" height="28" />
          </a>
          <a
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={toogleBugerMenu}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isToogled && `is-active`}`}>
          <div className="navbar-end">
            <a className="navbar-item" href="/course_grands_pieds">
              Course des Grands Pieds
            </a>
            <div className="navbar-item has-dropdown is-hoverable" href="/">
              <div className="navbar-link">Le Club</div>

              <div className="navbar-dropdown">
                <a className="navbar-item" href="/club/club_presentation">
                  Présentation
                </a>
                <a className="navbar-item" href="/club/comite">
                  Comité
                </a>
                <a className="navbar-item" href="/club/entraineurs">
                  Entraîneurs
                </a>
                <a className="navbar-item" href="/club/documents">
                  Documents
                </a>
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable" href="/">
              <div className="navbar-link">Course</div>

              <div className="navbar-dropdown">
                <a className="navbar-item" href="/course/maximal_race_2022">
                  Maximal Race 2022
                </a>
                <a className="navbar-item" href="/course/maximal_race_2021">
                  Maximal Race 2021
                </a>
                <a className="navbar-item" href="/course/joey_under_the_sun">
                  Joey under the sun
                </a>
                <a className="navbar-item" href="/course/info">
                  Les 5km 2020
                </a>
                <a className="navbar-item" href="/course/maximal_race_2020">
                  Maximal Race 2020
                </a>
                <a className="navbar-item" href="/course/resultats">
                  Résultats
                </a>
              </div>
            </div>

            <a className="navbar-item" href="/entrainements">
              Entraînement
            </a>

            <a className="navbar-item" href="/photos">
              Photos
            </a>

            <a className="navbar-item" href="/news_agenda">
              News & Agenda
            </a>

            <a className="navbar-item" href="/contact">
              Contact
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}
