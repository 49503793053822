import React from "react"
import NavBar from "./navbar"
import Footer from "./footer"

export default function Layout({ children }) {
  return (
    <div>
      <NavBar />
      <div
        style={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}
      >
        {children}
      </div>
      <Footer />
    </div>
  )
}
