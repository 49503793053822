import React from "react"
import Layout from "../components/layout/layout"
import "./mystyles.scss"

export default function pageNotFound() {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <h1 className="title">Error 404</h1>
          <h2 className="subtitle">
            Woops. Looks like this page doesn't exist. Here is a cat picture
            instead.
          </h2>
          <figure className="image">
            <img src="http://placekitten.com/1080/1920" alt="Placeholder" />
          </figure>
        </div>
      </section>
    </Layout>
  )
}
