import React from "react"
import MainLogoWhite from "../../img/logo/TeamKangourou_Logo.svg"

export default function Footer() {
  return (
    <footer className="footer has-text-white">
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <figure className="image is-128x128">
                <img src={MainLogoWhite} alt="logo" />
              </figure>
            </div>
            <div className="column">
              <h3 className="title is-5 has-text-white"> Contact </h3>
              <a href="mailto:secretaire@team-kangourou.ch">
                secretaire@team-kangourou.ch
              </a>
            </div>
            <div className="column">
              <h3 className="title is-5 has-text-white"> Le Club </h3>
              <a className="has-text-white" href="/club/club_presentation">
                Présentation <br />
              </a>
              <a className="has-text-white" href="/club/comite">
                Comité <br />
              </a>
              <a className="has-text-white" href="/club/entraineurs">
                Entraîneurs
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="content has-text-centered">
        <p>© 2020-2022 Team-Kangourou. Tous droits réservés.</p>
      </div>
    </footer>
  )
}
